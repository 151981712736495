import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';

const Copyright = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`${`${t('components.copyright')}`} © `}
      <Link color="inherit" href={'https://futurevending.hu'} target="_blank">
        {'Future Vending Kft.'}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default Copyright;
